import React from 'react'
import { graphql } from 'gatsby'
import { useQuery } from '@apollo/client'

import Layout from '../layout'
import { AbstractItem } from '../components/templates'
import { BandcampEmbed, Carousel, ItemHeader, ItemPrice, SidebarHeader, Tracklist } from '../components/molecules'
import { UIView, BlockDivider, ItemContent, PurchaseButton, LoadingBlock } from '../components/atoms'
import { pluckMetaValueFromShopifyProduct, buildVinylDescription } from '../utils'
import { STOCK_STATUS_QUERY } from '../fragments'
import RelatedItemsSection from '../components/organisms/RelatedItemsSection'

const Vinyl = ({ data, location }) => {
  const vinyl = data.shopifyProduct || {}
  const relatedMerch = data.relatedMerch.nodes
  const title = pluckMetaValueFromShopifyProduct(vinyl, 'artists')
  const subtitle = pluckMetaValueFromShopifyProduct(vinyl, 'title')
  const digitalUrl = pluckMetaValueFromShopifyProduct(vinyl, 'digital_link')
  const tracklist = pluckMetaValueFromShopifyProduct(vinyl, 'tracklist')
  const variant = vinyl.variants[0]

  const stockStatusQueryResult = useQuery(STOCK_STATUS_QUERY, {
    variables: { handle: data.shopifyProduct.handle }
  })

  return (
    <Layout pageName={vinyl.title} location={location}>
      <UIView
        desktop={() => (
          <AbstractItem
            left={
              <>
                <Carousel images={vinyl.images} />
              </>
            }
            middle={
              <>
                <ItemHeader
                  title={title}
                  subtitle={subtitle}
                  description={buildVinylDescription(vinyl)}
                  itemType={vinyl.productType.toLowerCase()}
                />
                {digitalUrl && digitalUrl.startsWith('<iframe') && <BandcampEmbed embedString={digitalUrl} />}
                <ItemContent content={vinyl.descriptionHtml} />
              </>
            }
            right={
              <>
                <div>
                  <SidebarHeader>Tracklist</SidebarHeader>
                  <BlockDivider />
                </div>
                <Tracklist tracklist={tracklist} />
                <BlockDivider />
                {variant.price !== '0.00' && (
                  <>
                    <ItemPrice price={variant.price} compareAtPrice={variant.compareAtPrice} />
                    {stockStatusQueryResult.data && (
                      <>
                        <PurchaseButton
                          variantId={variant.shopifyId}
                          itemType={vinyl.productType}
                          soldOut={!stockStatusQueryResult?.data?.productByHandle?.availableForSale}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            }
          />
        )}
        mobile={() => (
          <AbstractItem
            left={<Carousel images={vinyl.images} />}
            middle={
              <>
                <BlockDivider itemType={vinyl.productType.toLowerCase()} />
                <ItemHeader
                  title={title}
                  subtitle={subtitle}
                  description={buildVinylDescription(vinyl)}
                  divider={false}
                />
                {variant.price !== '0.00' && (
                  <>
                    <ItemPrice price={variant.price} compareAtPrice={variant.compareAtPrice} />
                    {stockStatusQueryResult.data && (
                      <>
                        <PurchaseButton
                          variantId={variant.shopifyId}
                          itemType={vinyl.productType}
                          soldOut={!stockStatusQueryResult?.data?.productByHandle?.availableForSale}
                        />
                      </>
                    )}
                  </>
                )}
                {digitalUrl && digitalUrl.startsWith('<iframe') && <BandcampEmbed embedString={digitalUrl} />}
              </>
            }
            right={
              <>
                <ItemContent content={vinyl.descriptionHtml} />
                <div>
                  <SidebarHeader>Tracklist</SidebarHeader>
                  <BlockDivider />
                </div>
                <Tracklist tracklist={tracklist} />
              </>
            }
          />
        )}
      />
      <RelatedItemsSection items={relatedMerch} />
    </Layout>
  )
}

export const query = graphql`
  query VinylQuery($pagePath: String!) {
    shopifyProduct: shopifyProduct(handle: { eq: $pagePath }) {
      handle
      title
      productType
      metafields {
        key
        namespace
        value
      }
      variants {
        shopifyId
        price
        compareAtPrice
      }
      images {
        gatsbyImageData(layout: CONSTRAINED, height: 840)
      }
      descriptionHtml
    }
    relatedMerch: allShopifyProduct(
      filter: { productType: { eq: "BSR Releases" } }
      sort: { fields: createdAt, order: DESC }
      limit: 6
    ) {
      nodes {
        handle
        productType
        title
        createdAt
        tags
        priceRangeV2 {
          minVariantPrice {
            amount
          }
          maxVariantPrice {
            amount
          }
        }
        variants {
          shopifyId
          price
          title
          compareAtPrice
        }
        metafields {
          namespace
          key
          value
        }
        images {
          gatsbyImageData(layout: CONSTRAINED, width: 512)
        }
      }
    }
  }
`

export default Vinyl
